import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { deleteAll } from '../../store/api-actions';
import { getAllProducts } from '../../store/app-data/selectors';

import Menu from './../../components/menu/menu.jsx';
import ProductsList from './../../components/products-list/products-list.jsx';
import ProductAnalysisInfo from './../../components/product-analysis-info/product-analysis-info.jsx';
import PopupAddProducts from './../../components/popup-add-products/popup-add-products.jsx';

const AnalysisPage = (props) => {
  const dispatch = useDispatch();

  const [currentProduct, setCurrentProduct] = useState(null);
  const [isOpenPopupAddProduct, setIsOpenPopupAddProduct] = useState(false);

  const products = useSelector(getAllProducts);

  return (
    <>
      <Menu />
      <section className="page-content page__content">
        <header className="page-content__header standart-block">
          <h1 className="header header--1">Анализ спроса</h1>

          <button
            className="button"
            type="button"
            onClick={async () => {
              await dispatch(deleteAll());
            }}
          >
            Отчистить все данные
          </button>
        </header>

        <div className="page-content__inner page-content__inner--main">
          <section className="products standart-block page-content__products">
            <h2 className="header header--2 header--space-bottom">Товары</h2>

            <button
              className="button button--add-excel products__add-button"
              onClick={() => {
                setIsOpenPopupAddProduct(true)
              }}
            >
              <svg className="button__icon" width="30px" height="30px" viewBox="0 0 24 24">
                <path d="M6 12H18M12 6V18" stroke="#ffffff" strokeWidth="3" strokeLinecap="round"
                      strokeLinejoin="round"/>
              </svg>
              <span
                className="button__text"
              >
                Добавить товары
              </span>
            </button>

            <ProductsList
              products={products}
              currentProduct={currentProduct}
              onClickCurrentProduct={setCurrentProduct}
            />
          </section>

          <section className="info page-content__info">

            {
              products.length !== 0
                && currentProduct !== null
                ? <ProductAnalysisInfo
                  product={currentProduct}
                /> : ''
            }

            {
              products.length === 0 || currentProduct === null
                ?
                <div className="info__content standart-block">
                  <p>Пожалуйста, выберите товар из списка или добавьте товары при помощи кнопки
                    <a
                      href="#"
                      onClick={() => {
                        setIsOpenPopupAddProduct(true);
                      }}
                    > «Добавить товары»</a>
                  </p>
                </div> : ''

            }
          </section>
        </div>
      </section>
      {
        isOpenPopupAddProduct
        ?
          <PopupAddProducts
            isOpen={isOpenPopupAddProduct}
            setIsOpen={setIsOpenPopupAddProduct}
          />
          :''
      }

    </>
  );
}

export default AnalysisPage;
