import { createSlice } from '@reduxjs/toolkit';
import { NameSpace, SortType } from '../../const';

import {
  createProject,
  createProducts,
  fetchAllProducts,
  deleteAll
} from '../api-actions.js';

const initialState = {
  products: [],
  projects: [],
  sortType: null
}

export const appData = createSlice({
  name: NameSpace.DATA,
  initialState,
  reducers: {
    loadProducts: (state, action) => {
      state.products = [];

      state.products = action.payload;
    },
    sortProducts: (state) => {
      if (state.sortType === SortType.TOTAL_SHOW_ASC) {
        state.products.sort((firstProduct, secondProduct) => {
          return firstProduct.totalShow - secondProduct.totalShow;
        });

        state.sortType = SortType.TOTAL_SHOW_DESC;
      } else {
        state.products.sort((firstProduct, secondProduct) => {
          return secondProduct.totalShow - firstProduct.totalShow;
        });

        state.sortType = SortType.TOTAL_SHOW_ASC;
      }

    }
  },
  extraReducers(builder) {
    builder
      // ПОЛУЧЕНИЕ СПИСКА ТОВАРОВ
      .addCase(fetchAllProducts.pending, (state) => {

      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.products = [];
        if (state.sortType !== null && state.sortType === SortType.TOTAL_SHOW_DESC) {
          state.products = action.payload.sort((firstProduct, secondProduct) => {
            return firstProduct.totalShow - secondProduct.totalShow;
          });
        } else {
          state.products = action.payload.sort((firstProduct, secondProduct) => {
            return secondProduct.totalShow - firstProduct.totalShow;
          });
        }
      })
      .addCase(fetchAllProducts.rejected, (state) => {

      })
    //  ЗАПИСЬ В БАЗУ ДАННЫХ НОВЫХ ТОВАРОВ
      .addCase(createProducts.pending, (state) => {

      })
      .addCase(createProducts.fulfilled, (state, action) => {
        console.log('Товары записаны в базу данных: ', action.payload);
      })
      .addCase(createProducts.rejected, (state) => {

      })
      // ЗАПИСЬ ИНФОРМАЦИИ О ПРОЕКТЕ В БАЗУ ДАННЫХ
      .addCase(createProject.pending, (state) => {

      })
      .addCase(createProject.fulfilled, (state, action) => {
        console.log('Информация о созданном проекте: ', action.payload);
      })
      .addCase(createProject.rejected, (state) => {

      })
    // УДАЛЕНИЕ ВСЕХ ДАННЫХ
      .addCase(deleteAll.pending, (state) => {

      })
      .addCase(deleteAll.fulfilled, (state) => {
        state.projects = [];
        state.products = [];
      })
      .addCase(deleteAll.rejected, (state) => {

      });
  }
})

export const {loadProducts, sortProducts} = appData.actions;
